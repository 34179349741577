import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import config from "../../data/SiteConfig"
import SEO from "../components/SEO"
import styled from "styled-components"
const PostBody = styled.div`
	img,
	video {
		height: auto;
		max-width: 100%;
	}
`
export const data = graphql`
	query($slug: String!) {
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			excerpt
			html
			frontmatter {
				title
				author
				description
				thumbnail {
					childImageSharp {
						fixed(width: 150, height: 150) {
							...GatsbyImageSharpFixed
						}
					}
				}
				date
				slug
				categories
				template
			}
		}
	}
`

const PostTemplate = ({ data: { markdownRemark: post } }) => {
	return (
		<Layout>
			<SEO
				description={post.frontmatter.description}
				postPath={post.frontmatter.slug}
				postNode={post}
				postSEO
				title={`${post.frontmatter.title} – ${config.siteTitle}`}
			/>
			<PostBody dangerouslySetInnerHTML={{ __html: post.html }} />
		</Layout>
	)
}

export default PostTemplate
